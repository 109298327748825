import requestService from './requestService';

export class DictionaryService {
  static async getLetters(dictionaryId, languageId) {
    const path = '/rest/dictionary/letters?dictionaryId=' +
      dictionaryId +
      '&languageId=' +
      languageId;

    return requestService.makeGetRequest(path);
  }

  static async getWords(phrase, page, limit) {
    const path = '/rest/dictionary/words?dictionaryId=1&originalLanguageId=1&translationLanguageId=2&phrase=' +
      phrase +
      '&page=' + page + '&limit=' + limit;

    return requestService.makeGetRequest(path);
  }

  static async getWord(wordId) {
    const path = '/rest/dictionary/words?dictionaryId=1&originalLanguageId=1&translationLanguageId=2&wordId=' + wordId;

    return requestService.makeGetRequest(path);
  }
}

const dictionaryService = DictionaryService;
export default dictionaryService;
