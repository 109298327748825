<template>
  <template v-if="deviceType === 'desktop'">
    <Navbar v-bind:title="'Słownik'"></Navbar>

    <div class="row">
      <div class="col-4 fm-background-main-color vh-100">
        <div class="row fm-search-input">
          <div class="col-md-3"></div>
          <div class="col-md-6">
            <div class="input-group mb-3">
              <input type="text" class="form-control" placeholder="Znajdź słowo" aria-label="find-word"
                     aria-describedby="search-icon" v-model="word"  v-on:keyup.enter="displayWords(word, 1, 12)">
              <span class="input-group-text" id="search-icon" v-on:click="displayWords(word, 1, 12)"><i class="bi bi-search"></i></span>
            </div>
          </div>
          <div class="col-md-3"></div>
        </div>

        <div class="row">
          <div class="col-md-3"></div>
          <div class="col-md-6" v-on:click="displayLettersList()">
            <div class="row align-items-center">
              <div class="col-9 text-start ">
                <span class="fm-choose-letter-label fm-label-main-color">Wybierz literę</span>
              </div>
            </div>
            <div class="row">
              <template v-for="letter in letters" v-bind:key="letter.id">
                <div class="col-3">
                  <button type="submit"
                          class="btn fm-letter-button fm-desktop-letter-button"
                          v-bind:class="letter === currentlySelectedLetter ? 'btn-primary fm-button-main-background-color' : 'btn-outline-primary fm-button-main-border-color'"
                          v-on:click="selectLetter(letter, 12)">
                    {{letter.toUpperCase()}}
                  </button>
                </div>
              </template>
            </div>
          </div>
          <div class="col-md-3"></div>
        </div>
      </div>

      <div class="col-8">
        <div class="row mt-5">
          <template v-for="(word, index) in words" v-bind:key="word.id">
            <div v-if="index % 2 === 0" class="col-1"></div>
            <div class="col-5">
              <div class="card mb-3">

                <div class="row g-0 align-items-center">

                  <div class="col-2">
                    <img v-bind:src="word.originalLanguage.image" class="img-fluid h-100 fm-card-image">
                  </div>

                  <div class="col-8">
                    <div class="card-body fm-card">
                      <h5 class="card-title text-start fm-label-main-color fm-original-language-word">
                        {{ word.originalLanguage.word }}</h5>
                      <p class="card-text text-start">{{ word.translationLanguage.word }}</p>
                    </div>
                  </div>

                  <div class="col-2">
                    <i class="bi bi-volume-down-fill text-center" style="font-size: 2rem"
                       v-on:click="playAudio(word.originalLanguage.sound)"></i>
                  </div>

                </div>

              </div>
            </div>
            <div v-if="index % 2 === 1" class="col-1"></div>
          </template>
        </div>
        <div class="row align-items-center">
          <div class="col-md-12">
            <nav aria-label="Page navigation example">
              <ul class="pagination justify-content-center align-items-center">
                <li class="page-item">
                  <a class="page-link fm-page-item" aria-label="Previous" v-on:click="selectPage(currentlySelectedPage - 1, 12)">
                    <i class="bi bi-chevron-left fm-navigate-chevron"></i>
                  </a>
                </li>

                <template v-for="index in pagesToDisplay" :key="index">
                  <li class="page-item">
                    <a v-bind:id="'page-' + index" class="page-link fm-page-item"
                       v-on:click="selectPage(index, 12);">
                      {{index}}
                    </a>
                  </li>
                </template>

                <li class="page-item">
                  <a class="page-link fm-page-item" aria-label="Next"
                     v-on:click="selectPage(currentlySelectedPage + 1, 12)">
                    <i class="bi bi-chevron-right fm-navigate-chevron"></i>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>

  </template>

<!--  <div class="col-md-1"></div>-->
<!--  <div class="col-md-5">-->
<!--    <div class="card mb-3">-->

<!--      <div class="row g-0 align-items-center">-->

<!--        <div class="col-2" v-on:click="redirectToWordDetailsPage(word.id)">-->
<!--          <img v-bind:src="word.originalLanguage.image" class="img-fluid h-100 fm-card-image">-->
<!--        </div>-->

<!--        <div class="col-8" v-on:click="redirectToWordDetailsPage(word.id)">-->
<!--          <div class="card-body fm-card">-->
<!--            <h5 class="card-title text-start fm-label-main-color fm-original-language-word">-->
<!--              {{ word.originalLanguage.word }}</h5>-->
<!--            <p class="card-text text-start">{{ word.translationLanguage.word }}</p>-->
<!--          </div>-->
<!--        </div>-->

<!--        <div class="col-2">-->
<!--          <i class="bi bi-volume-down-fill text-center" style="font-size: 2rem"-->
<!--             v-on:click="playAudio(word.originalLanguage.sound)"></i>-->
<!--        </div>-->

<!--      </div>-->

<!--    </div>-->
<!--  </div>-->

<!--  <div class="col-md-5">-->
<!--    <div class="card mb-3">-->

<!--      <div class="row g-0 align-items-center">-->

<!--        <div class="col-2" v-on:click="redirectToWordDetailsPage(word.id)">-->
<!--          <img v-bind:src="word.originalLanguage.image" class="img-fluid h-100 fm-card-image">-->
<!--        </div>-->

<!--        <div class="col-8" v-on:click="redirectToWordDetailsPage(word.id)">-->
<!--          <div class="card-body fm-card">-->
<!--            <h5 class="card-title text-start fm-label-main-color fm-original-language-word">-->
<!--              {{ word.originalLanguage.word }}</h5>-->
<!--            <p class="card-text text-start">{{ word.translationLanguage.word }}</p>-->
<!--          </div>-->
<!--        </div>-->

<!--        <div class="col-2">-->
<!--          <i class="bi bi-volume-down-fill text-center" style="font-size: 2rem"-->
<!--             v-on:click="playAudio(word.originalLanguage.sound)"></i>-->
<!--        </div>-->

<!--      </div>-->

<!--    </div>-->
<!--  </div>-->

<!--  <div class="col-md-1"></div>-->

  <template v-if="deviceType === 'mobile'">
    <div class="container">

      <Navbar v-bind:title="'Słownik'"></Navbar>

      <template v-if="!displayLetterList">
        <div class="row fm-search-input">
          <div class="col-md-3"></div>
          <div class="col-md-6">
            <div class="input-group mb-3">
              <input type="text" class="form-control" placeholder="Znajdź słowo" aria-label="find-word"
                     aria-describedby="search-icon" v-model="word">
              <span class="input-group-text" id="search-icon"  v-on:click="displayWords(word, 1, 6)"><i class="bi bi-search"></i></span>
            </div>
          </div>
          <div class="col-md-3"></div>
        </div>

        <div class="row">
          <div class="col-md-3"></div>
          <div class="col-md-6" v-on:click="displayLettersList()">
            <div class="row align-items-center">
              <div class="col-9 text-start ">
                <span class="fm-choose-letter-label fm-label-main-color">Wybierz literę</span>
              </div>
              <div class="col-3 text-end">
                <button type="submit" class="btn btn-primary fm-letter-button fm-button-main-background-color">{{currentlySelectedLetter.toUpperCase()}}</button>
              </div>
            </div>
          </div>
          <div class="col-md-3"></div>
        </div>

        <hr>

        <template v-for="(word) in words" v-bind:key="word.id">
          <div class="row">
            <div class="col-md-3"></div>

            <div class="col-md-6">
              <div class="card mb-3">

                <div class="row g-0 align-items-center">

                  <div class="col-2" v-on:click="redirectToWordDetailsPage(word.id)">
                    <img v-bind:src="word.originalLanguage.image" class="img-fluid h-100 fm-card-image">
                  </div>

                  <div class="col-8" v-on:click="redirectToWordDetailsPage(word.id)">
                    <div class="card-body fm-card">
                      <h5 class="card-title text-start fm-label-main-color fm-original-language-word">
                        {{ word.originalLanguage.word }}</h5>
                      <p class="card-text text-start">{{ word.translationLanguage.word }}</p>
                    </div>
                  </div>

                  <div class="col-2">
                    <i class="bi bi-volume-down-fill text-center" style="font-size: 2rem"
                       v-on:click="playAudio(word.originalLanguage.sound)"></i>
                  </div>

                </div>

              </div>
            </div>

            <div class="col-md-3"></div>

          </div>
        </template>

        <div class="row align-items-center">
          <div class="col-md-12">
            <nav aria-label="Page navigation example">
              <ul class="pagination justify-content-center align-items-center">
                <li class="page-item">
                  <a class="page-link fm-page-item" aria-label="Previous" v-on:click="selectPage(currentlySelectedPage - 1)">
                    <i class="bi bi-chevron-left fm-navigate-chevron"></i>
                  </a>
                </li>

                <template v-for="index in pagesToDisplay" :key="index">
                  <li class="page-item">
                    <a v-bind:id="'page-' + index" class="page-link fm-page-item"
                       v-on:click="selectPage(index);">
                      {{index}}
                    </a>
                  </li>
                </template>

                <li class="page-item">
                  <a class="page-link fm-page-item" aria-label="Next"
                     v-on:click="selectPage(currentlySelectedPage + 1)">
                    <i class="bi bi-chevron-right fm-navigate-chevron"></i>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </template>

      <template v-else>

        <div class="row align-items-center">
          <div class="col-md-3"></div>
          <div class="col-md-6">
            <div class="row align-items-center">
              <div class="col-9 text-start ">
                <span class="fm-choose-letter-label fm-label-main-color">Wybierz literę</span>
              </div>
              <div class="col-3">
              </div>
            </div>
          </div>
          <div class="col-md-3"></div>
        </div>

        <hr>

        <template v-for="(letter) in letters" v-bind:key="letter">
          <div class="row">
            <div class="col-md-3"></div>
            <div class="col-md-6">
              <div class="row align-items-center" style="padding-bottom: 1.25rem;">
                <div class="col-2">
                  <button type="submit"
                          class="btn fm-letter-button"
                          v-bind:class="letter === currentlySelectedLetter ? 'btn-primary fm-button-main-background-color' : 'btn-outline-primary fm-button-main-border-color'"
                          v-on:click="selectLetter(letter)">
                    {{letter.toUpperCase()}}
                  </button>
                </div>
                <div class="col-10 text-start">
                  <span class="fm-choose-letter-label fm-label-main-color">as Alphabet</span>
                </div>
              </div>
            </div>
            <div class="col-md-3"></div>
          </div>

        </template>

      </template>

      <div class="col-md-10">
        <router-view/>
      </div>
    </div>
  </template>

</template>

<script>
import dictionaryService from '../../services/dictionaryService';
import Navbar from '../../components/Navbar';

export default {
  name: 'Dictionary',
  components: { Navbar },
  data() {
    return {
      word: '',
      letters: [],
      words: [],
      pages: 0,
      totalCount: 0,
      currentlyUsedPhrase: 'a',
      currentlySelectedWordId: 1,
      wordData: '',
      currentlySelectedPage: 1,
      pagesToDisplay: [],
      currentlySelectedLetter: 'a',
      displayLetterList: false,
      deviceType: 'desktop'
    };
  },
  mounted() {
    this.initializeView();
  },
  unmounted() {
    window.addEventListener('resize', this.onResize);
  },
  watch: {
    // pages() {
    //   this.selectPage(1, 12);
    // }
  },
  methods: {
    onResize() {
      this.setDeviceType();
    },
    async setDeviceType() {
      if (window.outerWidth > 576) {
        this.deviceType = 'desktop';
      } else {
        this.deviceType = 'mobile';
      }
    },
    async initializeView() {
      window.addEventListener('resize', this.onResize);
      await this.setDeviceType();

      await this.displayLetters(1, 1);
      await this.displayWords(this.currentlyUsedPhrase, 1, 6);
      // await this.displayWordData(this.currentlySelectedWordId);
      if (this.deviceType === 'desktop') {
        await this.selectPage(1, 12);
      }

      if (this.deviceType === 'mobile') {
        await this.selectPage(1, 6);
      }
      // await this.getPageToDisplay();
      // this.markPageAsSelected();
    },
    async displayLetters(dictionaryId, languageId) {
      const response = await dictionaryService.getLetters(dictionaryId, languageId);
      const letters = response.data.letters.items;

      if (letters.length > 0) {
        this.letters = letters;
      } else {
        window.alert('No letters in response');
      }
    },
    async displayWords(phrase, page, limit) {
      this.currentlyUsedPhrase = phrase;
      const response = await dictionaryService.getWords(phrase, page, limit);
      const words = response.data.words;

      if (words.items.length > 0) {
        this.words = words.items;
        this.totalCount = words.totalCount;
        this.pages = Math.ceil((words.totalCount / limit));
      } else {
        window.alert('No words in response');
      }
    },
    async displayWordData(wordId) {
      const response = await dictionaryService.getWord(wordId);
      const word = response.data.words.items[0];

      if (word) {
        this.wordData = word;
        this.sound = word.originalLanguage.sound;
        const wordAudio = document.getElementById('word-audio');
        if (wordAudio) {
          wordAudio.load();
        }
      } else {
        window.alert('No letters in response');
      }
    },
    async selectPage(page, limit = 6) {
      if (page <= 0) {
        page = 1;
      }

      if (page > this.pages) {
        page = this.pages;
      }

      this.currentlySelectedPage = page;
      await this.getPageToDisplay();
      this.markPageAsSelected();
      await this.displayWords(this.currentlyUsedPhrase, page, limit);
    },
    async getPageToDisplay() {
      this.pagesToDisplay = [];

      if (this.currentlySelectedPage === 1) {
        if (this.pages === 1) {
          this.pagesToDisplay = [1];
        }

        if (this.pages === 2) {
          this.pagesToDisplay = [1, 2];
        }

        if (this.pages >= 3) {
          this.pagesToDisplay = [1, 2, 3];
        }
      }

      if (this.currentlySelectedPage === this.pages) {
        if (this.pages === 1) {
          this.pagesToDisplay = [1];
        }

        if (this.pages === 2) {
          this.pagesToDisplay = [1, 2];
        }

        if (this.pages >= 3) {
          this.pagesToDisplay = [
            this.currentlySelectedPage - 2,
            this.currentlySelectedPage - 1,
            this.currentlySelectedPage
          ];
        }
      }

      if (this.currentlySelectedPage > 1 && this.currentlySelectedPage < this.pages) {
        this.pagesToDisplay = [
          this.currentlySelectedPage - 1,
          this.currentlySelectedPage,
          this.currentlySelectedPage + 1
        ];
      }

      // this.markPageAsSelected();
    },
    markPageAsSelected() {
      const currentlySelectedElement = document.getElementsByClassName('fm-page-item-selected');

      if (currentlySelectedElement) {
        for (const element of currentlySelectedElement) {
          element.classList.remove('fm-page-item-selected');
        }
      }

      const pageElement = document.getElementById('page-' + this.currentlySelectedPage);
      pageElement.classList.add('fm-page-item-selected');
    },
    playAudio(soundUrl) {
      const audio = new Audio(soundUrl);
      audio.play();
    },
    displayLettersList() {
      this.displayLetterList = true;
    },
    selectLetter(selectedLetter, limit = 6) {
      this.currentlySelectedLetter = selectedLetter;
      this.currentlyUsedPhrase = selectedLetter;
      this.displayLetterList = false;
      this.selectPage(1, limit);
    },
    redirectToWordDetailsPage(wordId) {
      this.$router.push({ name: 'WordDetails', params: { id: wordId } });
    }
  }
};
</script>

<style scoped>
hr {
  margin-left: -12px;
  margin-right: -12px;
}

.fm-search-input {
  margin-top: 1rem;
}

.fm-choose-letter-label {
  font-size: 1.5rem;
  font-weight: bold;
}

.fm-letter-button {
  height: 3rem;
  width: 3rem;
  font-size: 1.25rem;
  font-weight: bold;
}

.fm-original-language-word {
  font-weight: bold;
}

.fm-card {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.fm-page-item {
  font-size: 1.25rem;
  font-weight: bold;
  height: 3.5rem;
  width: 4rem;
  padding-top: 20%;
  color: var(--royal-orange);
}

.fm-page-item-selected {
  color: white;
  background-color: var(--royal-orange);
}

.fm-card-image {
 padding-left: 0.5rem;
}

.fm-desktop-letter-button {
  margin-bottom: 1rem;
}
</style>
